#locker-customization{
    .load_locker_customization{
        font-size: 16px;
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .load_locker_customization_error{
        div{
            text-align: center;
            margin-top: 20px;
            font-size: 16px;
            font-weight: 600;
        }
    }
    .disabled{
        pointer-events: none !important;
        opacity: 0.65;
    }
    #locker-customization-form{
        #main-primary-picker, #second-primary-picker, #third-primary-picker{
            pointer-events: none !important;
        }
        .fa{
            cursor: pointer;
        }
        .upload-btn-wrapper{
            cursor: pointer;
        }
        .row{
            .title{
                color: #777;
                font-size: 16px;
                font-weight: 600;
            }
            .phone{
                display: flex;
                align-items: center;
                margin-top: 15px;
                input{
                    flex: 1;
                    margin-left: 15px;
                }
            }
            .d-flex{
                display: flex;
                align-items: center;
                margin-top: 15px;
                min-height: 32px;
                label{
                    min-width: 50px;
                    margin-bottom: 0;
                }
                span{
                    font-size: 13px;
                }
                .title-logo{
                    width: 80px;
                }
                .title-special{
                    width: 110px;
                }
                .has-margin{
                    margin-left: 15px;
                }
                .mr-5{
                    margin-left: 15px;
                }
                .mr-20{
                    margin-left: 20px;
                }
                .mr-25{
                    margin-left: 30px;
                }
                .logo{
                    width: 80px;
                    height: 30px;
                    border-radius: 5px;
                    background-color: red;
                }
                .fa-eye, .fa-pencil, .fa-trash{
                    color: #ff8a00;
                }
                .upload-btn-wrapper {
                    position: relative;
                    overflow: hidden;
                    display: inline-block;
                    margin-left: 5px;
                    border: 1px solid #777;
                    color: #fff;
                    background-color: #777;
                    border-radius: 5px;
                    cursor: pointer;
                    }
                    .upload-btn-wrapper{
                    .btn {
                        border: 1px solid #777;
                        color: #fff;
                        background-color: #777;
                        border-radius: 5px;
                        }
                    }
                    .popover-content{
                        display: flex;
                        a{
                        display: flex;
                        align-items: center; 
                        i{
                            margin-right: 5px;
                        }
                        }
                        .btn-default{
                            color: #ff8300;
                        }
                    }
                    .upload-btn-wrapper input[type=file] {
                        position: absolute;
                        top: 0;
                        right: 0;
                        margin: 0;
                        padding: 0;
                        font-size: 20px;
                        cursor: pointer;
                        opacity: 0;
                    }
            }
        }
        .text-right{
            text-align: right;
            margin-top: 20px;
        }
    }
    @media only screen and (max-width: 767px) {
        .custom_title{
            margin-top: 10px;
        }
        .d-flex {
            label{
                width: 110px !important;
                flex: unset !important;
            }
        }
        .phone{
            label{
                width: 120px !important;
            }
        }
    }
    @media (min-width: 768px) and (max-width: 1200px) {
        .wrap_special label, .wrap_support label{
            margin-top: 10px;
        }
    }
    @media (min-width: 1200px) and (max-width: 1400px) {
        .d-flex{
            .has-margin{
                margin-left: 8px !important;
            }
        }
        .title{
            font-size: 13px !important;
        }
    }
}
.viewImage{
    .panel-primary{
        width: 100%;
        .panel-heading{
            background-color: #f6f6f6;
            border-color: #f6f6f6;
            border-bottom: 1px solid #ddd;
            display: flex;
            justify-content: space-between;
        }
        .panel-body{
            text-align: center;
            width: 100%;
            padding: 100px;
            img{
                max-width: 100%;
                height: auto;
                opacity: 0;
            }
            .noImage{
                font-size: 18px;
            }
        }
        .panel-title{
            color: #777;
        }
    }
}
#modal-delete-screen-saver{
  .modal-footer{
    display: flex;
    justify-content: flex-end;
  }
}
#modal-preview-help-screen .modal-body, #modal-preview-thank-you-screen .modal-body{
    word-break: break-word;
    overflow: auto;
}
