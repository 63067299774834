.header .toggle-sidebar-left {
    background: $color-menu;
}

ul.nav-main li a {
    color: $color-menu;
}

ul.nav-main li a:active {
    font-size: 1.3rem;
    color: $color-menu-inverse;
    white-space: nowrap;
    text-overflow: ellipsis;
}

ul.nav-main > li > a:hover, ul.nav-main > li > a:focus {
    background-color: $color-menu-hover;
}

ul.nav-main > li.nav-active > a {
    box-shadow: 2px 0 0 $color-menu-current inset;
}

ul.nav-main > li.nav-active > i {
    color: $color-menu;
}

ul.nav-main li .nav-children li.nav-active > a {
    color: $color-menu-sub-active;
}

ul.nav-main > li.nav-expanded > a {
    background: $color-menu-current;
    color: $color-menu-inverse;
}

ul.nav-main li .nav-children li a:hover, ul.nav-main li .nav-children li a:focus {
    background: $color-menu-hover;
}

.nav-pills > .active a,
.nav-pills > .active a:hover,
.nav-pills > .active a:focus {
    background-color: $color-menu;
}

.nav-pills-primary > li a:hover,
.nav-pills-primary > li a:focus {
    color: $color-menu;
    background-color: $color-menu-hover;
}

.nav-pills-primary > li.active > a,
.nav-pills-primary > li.active > a:hover,
.nav-pills-primary > li.active > a:active,
.nav-pills-primary > li.active > a:focus {
    background-color: $color-menu;
}
