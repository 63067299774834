.barcode-val {
  margin-bottom: 10px;
  .barcode-validate {
    display: flex;
    align-items: center;
  }
  i {
    color: red;
    font-size: 20px;
  }
}

.courier-edit-form-header{
  display: flex;
  align-items: center;

  .btn{
    margin-left: auto;
  }
}

form#courier,
#modal-courier-edit {
  #Checkbox_barcode {
    margin-bottom: 5px;
  }

  .checkbox-courier-pre-advice {
    margin-top: 0px;
  }
}

form#courier {
  #courier-barcode {
    margin-bottom: 10px;
  }

  #courier-barcode[read-only="true"] {
    a {
      pointer-events: none;
    }
    i {
      color: #777 !important;
    }
    
  }
}

#modal-courier-edit {
  #courier_prt_barcode {
    margin-bottom: 10px;
  }
  #courier_prt_barcode[read-only="true"] {
    i, .courier-add-barcode {
      display: none;
    }
  }
}