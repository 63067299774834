// Bootstrap Configuration
$screen-md-max: $screen-lg - 1;

// Color mixing
$mix-disabled-base: 40;
$mix-lighten-base: 20;
$mix-notification-background: 80;
$mix-translucency-partial: 0.95;

$color-default-active: mix(white, $color-default, $mix-lighten-base * 2);
$color-default-background: mix(white, $color-default, $mix-notification-background);
$color-default-disabled: mix(white, $color-default, $mix-disabled-base);
$color-default-disabled-hover: mix(white, $color-default, $mix-disabled-base * 2);
$color-default-hover: mix(white, $color-default, $mix-lighten-base);
$color-default-dark: mix(white, $color-default, $mix-lighten-base * 4);

$color-primary-active: $color-primary;
$color-primary-background: mix(white, $color-primary, $mix-notification-background);
$color-primary-disabled: $color-primary;
$color-primary-disabled-hover: mix(white, $color-primary, $mix-disabled-base * 2);
$color-primary-hover: mix(white, $color-primary, $mix-lighten-base);
$color-primary-dark: mix(white, $color-primary, $mix-lighten-base * 4);

$color-success-active: mix(white, $color-success, $mix-lighten-base * 2);
$color-success-background: mix(white, $color-success, $mix-notification-background);
$color-success-disabled: mix(white, $color-success, $mix-disabled-base);
$color-success-disabled-hover: mix(white, $color-success, $mix-disabled-base * 2);
$color-success-hover: mix(white, $color-success, $mix-lighten-base);
$color-success-dark: mix(white, $color-success, $mix-lighten-base * 4);

$color-warning-active: mix(white, $color-warning, $mix-lighten-base * 2);
$color-warning-background: mix(white, $color-warning, $mix-notification-background);
$color-warning-disabled: mix(white, $color-warning, $mix-disabled-base);
$color-warning-disabled-hover: mix(white, $color-warning, $mix-disabled-base * 2);
$color-warning-hover: mix(white, $color-warning, $mix-lighten-base);
$color-warning-dark: mix(white, $color-warning, $mix-lighten-base * 4);

$color-danger-active: mix(white, $color-danger, $mix-lighten-base * 2);
$color-danger-background: mix(white, $color-danger, $mix-notification-background);
$color-danger-disabled: mix(white, $color-danger, $mix-disabled-base);
$color-danger-disabled-hover: mix(white, $color-danger, $mix-disabled-base * 2);
$color-danger-hover: mix(white, $color-danger, $mix-lighten-base);
$color-danger-dark: mix(white, $color-danger, $mix-lighten-base * 4);

$color-info-active: mix(white, $color-info, $mix-lighten-base * 2);
$color-info-background: mix(white, $color-info, $mix-notification-background);
$color-info-disabled: mix(white, $color-info, $mix-disabled-base);
$color-info-disabled-hover: mix(white, $color-info, $mix-disabled-base * 2);
$color-info-hover: mix(white, $color-info, $mix-lighten-base);
$color-info-dark: mix(white, $color-info, $mix-lighten-base * 4);

$color-secondary-active: mix(white, $color-secondary, $mix-lighten-base * 2);
$color-secondary-background: mix(white, $color-secondary, $mix-notification-background);
$color-secondary-disabled: mix(white, $color-secondary, $mix-disabled-base);
$color-secondary-disabled-hover: mix(white, $color-secondary, $mix-disabled-base * 2);
$color-secondary-hover: mix(white, $color-secondary, $mix-lighten-base);
$color-secondary-dark: mix(white, $color-secondary, $mix-lighten-base * 4);

$color-tertiary-active: mix(white, $color-tertiary, $mix-lighten-base * 2);
$color-tertiary-background: mix(white, $color-tertiary, $mix-notification-background);
$color-tertiary-disabled: mix(white, $color-tertiary, $mix-disabled-base);
$color-tertiary-disabled-hover: mix(white, $color-tertiary, $mix-disabled-base * 2);
$color-tertiary-hover: mix(white, $color-tertiary, $mix-lighten-base);
$color-tertiary-dark: mix(white, $color-tertiary, $mix-lighten-base * 4);

$color-quaternary-active: mix(white, $color-quaternary, $mix-lighten-base * 2);
$color-quaternary-background: mix(white, $color-quaternary, $mix-notification-background);
$color-quaternary-disabled: mix(white, $color-quaternary, $mix-disabled-base);
$color-quaternary-disabled-hover: mix(white, $color-quaternary, $mix-disabled-base * 2);
$color-quaternary-hover: mix(white, $color-quaternary, $mix-lighten-base);
$color-quaternary-dark: mix(white, $color-quaternary, $mix-lighten-base * 4);

// Element Z-Index
$pnotify-z-index: 19999;

// Element Sizes
// See _locker.scss for locker bank CSS definitions
$width-locker-bank-standard: 180px;
$height-locker-tower: 345px;
