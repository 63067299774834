#first-visit{
    width: 100%;
    height: 50px;
    display: flex;
    min-height: 200px;
    flex-direction: column;
    .create{
        flex: 1;
        align-items: center;
        display: flex;
        a{
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-transform: uppercase;
        }
    }
    .text-center{
        a{
            cursor: pointer;
        }
    }
}