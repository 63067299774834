#mobile-app {
    .mobile-app-info {
        padding-right: 30px;
        padding-left: 30px;
    }
    .mobile-app-logo {
        display: flex;
        .column {
            padding-left: 20px;
            padding-right: 20px;
        }
    }
    .skip-download {
        background-color: #fff !important;
        padding-top: 45px;
        padding-bottom: 60px;
        padding-left: 70px;
        padding-right: 70px;
    }
}