// Search Form
.refresh-indicator {
    display: none;
    float: left;
    margin: 0;
}

.panel-footer .refresh-indicator i {
    position: relative;
    top: 5px;
    left: 0;
}

.panel-footer .checkbox {
    margin-top: 5px;
    margin-bottom: 5px;
}

.panel-footer .checkbox input[type=checkbox],
.panel-footer .checkbox input[type=radio] {
    margin: 5px 0 5px -20px;
}

#form-filter button .fa {
    width: 14px;
}

// Search Results
.search-content .search-toolbar .nav-pills li.active a {
    color: $color-primary;
    border-bottom-color: $color-primary;
}

.search-results-list .result-thumb .fa {
    background: $color-primary;
}
