// Table Layout
div.table-container {
    overflow-y: auto;
}

#units_table_wrapper div.table-container, #ras-detail-table-wrapper div.table-container {
    overflow-y: unset;
}

// Table Actions
.table .actions {
    white-space: nowrap;
}

.table .actions, .table .actions-hover {
    vertical-align: top;
}

.table .editing .actions, .table .editing .actions-hover {
    vertical-align: middle;
}

.table .actions i:hover {
    cursor: pointer;
}

.table .actions a {
    margin-right: 0;
}

.table .actions i {
    margin-right: 5px;
}

.table .actions a, .table .actions-hover a,
.table .actions i, .table .actions-hover i {
    color: $color-primary;
}

// Table Sorting
table.table thead .sorting {
    background-image: url("/img/themes/" + $theme-base-directory + "/sort_both.png");
}

table.table thead .sorting_asc {
    background-image: url("/img/themes/" + $theme-base-directory + "/sort_asc.png");
}

table.table thead .sorting_desc {
    background-image: url("/img/themes/" + $theme-base-directory + "/sort_desc.png");
}

table.table thead .sorting_asc_disabled {
    background-image: url("/img/themes/" + $theme-base-directory + "/sort_asc_disabled.png");
}

table.table thead .sorting_desc_disabled {
    background-image: url("/img/themes/" + $theme-base-directory + "/sort_desc_disabled.png");
}

table.table thead .sorting,
table.table thead .sorting_asc,
table.table thead .sorting_desc,
table.table thead .sorting_asc_disabled,
table.table thead .sorting_desc_disabled {
    background-position: bottom 7px left;
    padding-left: 16px;
}

// Table Cell Content
table tbody td h4 {
    margin-top: 15px;
    margin-bottom: 7px;
    font-size: 1.3rem;
    font-weight: bold;
}

table tbody td h4 a {
    color: $color-default-inverse !important;

    &:active,
    &:hover {
        text-decoration: none;
    }
}

// Table Cell States
.table > thead > tr > td.primary,
.table > tbody > tr > td.primary,
.table > tfoot > tr > td.primary,
.table > thead > tr > th.primary,
.table > tbody > tr > th.primary,
.table > tfoot > tr > th.primary,
.table > thead > tr.primary > td,
.table > tbody > tr.primary > td,
.table > tfoot > tr.primary > td,
.table > thead > tr.primary > th,
.table > tbody > tr.primary > th,
.table > tfoot > tr.primary > th {
    color: $color-primary !important;
    background-color: $color-primary-background !important;
}

.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
    color: $color-success !important;
    background-color: $color-success-background !important;
}

.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info > td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th {
    color: $color-info !important;
    background-color: $color-info-background !important;
}

.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning > td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th {
    color: $color-warning !important;
    background-color: $color-warning-background !important;
}

.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
    color: $color-danger !important;
    background-color: $color-danger-background !important;
}

// DataTables Layout
.dataTables_wrapper .dataTables_length .select2-container {
    margin-right: 0;
    width: auto !important;
}

.dataTables_wrapper .dataTables_empty {
    display: none;
}

.dataTables_wrapper .input-sm {
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
}

.dataTables_length select {
    width: auto !important;
    display: inline-block !important;
}

.dataTables_export_toolbar {
    text-align: right;
    margin-bottom: 10px;
}
