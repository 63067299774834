.page-header {
    h2 {
        color: $color-default-inverse;
        border-bottom-color: $color-primary;
    }

    .breadcrumbs {
        margin-right: 18px;

        a,
        i,
        li:after,
        span {
            vertical-align: baseline;
        }

        i {
            position: relative;
            top: 2px;
        }
    }

    .sidebar-right-toggle {
        margin-left: 0;

        &:hover {
            color: $color-primary;
        }
    }
}
