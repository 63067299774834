.tab-content {
    .char-count {
        font-size: 14px;
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 2px 5px;
        background-color: $color-success;
        border-radius: $tabs-content-border-radius;
        color: $color-primary-inverse;
    }
}

.transition-highlight-fadeout {
    transition: background 2s;
}

.transition-highlight-green {
    background-color: $color-success;
    transition: background 2s;
}
