#div-tos-edit {
    display: none;
}

#tos-admin-content {
    height: 375px;
    overflow-y: scroll;
}

#form-field-content {
    width: 100%;
    height: 375px;
    display: none;
}
