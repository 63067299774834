// Anchors
a,
.btn-link {
    color: $color-primary;
}

a:hover,
a:focus,
.btn-link:hover,
.btn-link:focus {
    color: $color-primary-hover;
}

a:active,
.btn-link:active {
    color: $color-primary-active;
}

.btn-float-right {
    float: right;
    transform: translate(0, -20%);
}
