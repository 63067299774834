// Locker Video List
table.metadata thead th,
table.metadata tbody td {
    text-align: center;
}

table.locker-metadata thead th:nth-child(1),
table.locker-metadata thead th:nth-child(3),
table.locker-metadata tbody td:nth-child(1),
table.locker-metadata tbody td:nth-child(3) {
    width: 20%;
}

table.locker-metadata thead th:nth-child(2),
table.locker-metadata thead th:nth-child(4),
table.locker-metadata tbody td:nth-child(2),
table.locker-metadata tbody td:nth-child(4) {
    width: 30%;
}

table.locker-video-metadata thead th:nth-child(1),
table.locker-video-metadata thead th:nth-child(4),
table.locker-video-metadata tbody td:nth-child(1),
table.locker-video-metadata tbody td:nth-child(4) {
    width: 10%;
}

table.locker-video-metadata thead th:nth-child(2),
table.locker-video-metadata thead th:nth-child(3),
table.locker-video-metadata tbody td:nth-child(2),
table.locker-video-metadata tbody td:nth-child(3) {
    width: 40%;
}
