.input-daterange.form-filter-dates {
    width: auto !important;
}

.input-daterange.form-filter-dates input {
    border-radius: 3px;
    text-align: left;
}

.input-parcelhistory-group {
    display: block;
}

.heading-time {
    padding: 2px 0;
}

span.badge,
span.label {
    position: relative;
    top: -1px;
}

a.parcel-toggle:hover {
    text-decoration: none;
}

.parcel-activity,
.parcel-notifications,
.parcel-toggle {
    white-space: nowrap;
}

.code-reset,
.notification-resend,
.pickedup {
    margin-top: 4px;
}

.modal-picture {
    margin: 0 auto;
    display: block;
    max-width: 100%;
    font-size: 1.3rem;
    font-weight: bold;
}

#modal-image .modal-body .pickup-picture-image {
    max-width: 100%;
}
