// Layouts
.form-panel-initial-hidden {
    display: none;
}

.form-group .btn-group .disabled label {
    cursor: not-allowed;
    color: $color-muted;
}

.form-group input.disabled {
    cursor: not-allowed;
}

.form-group-compact {
    margin-bottom: 0;
}

.form-group-checkbox-radio .checkbox,
.form-group-checkbox-radio .radio {
    padding-top: 0;
}

.form-horizontal .form-group {
    margin-left: 0;
    margin-right: 0;
}

@media (min-width: 768px) {
    .form-inline .form-control {
        width: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .form-inline .form-group {
        margin-bottom: 15px;
    }
}

.form-filter {
    h3 {
        margin-top: $form-filter-heading-margin-top;
    }
}

.form-filter-report .form-group:last-child {
    margin-bottom: 5px;
}

.form-filter-report .dropdown-menu {
    left: auto;
    right: 0;
}

.form-filter-report .checkbox-inline,
.form-filter-report .radio-inline {
    top: 5px;
}

.form-control:focus,
select:focus {
    border-color: $color-primary-active;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba($color-primary, 0.3);
}

.form-control-error-icon {
    transform: translate(-25%, 50%);
}

.required {
    color: $color-primary;
}

// Buttons
.btn-form-group {
    margin-top: 27px;
}

.btn.btn-success:disabled {
    background-color: $color-default-inverse;
}

.btn-primary,
.btn-primary .fa {
    color: $color-primary-inverse !important;
}

.btn-primary.dropdown-toggle {
    border-left-color: $color-primary;
}

.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover,
.btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
    border-color: $color-primary-hover !important;
    background-color: $color-primary-hover;
}

.btn-primary:hover[disabled] {
    border-color: $color-secondary !important;
    background-color: $color-secondary;
}

body .btn-primary.dropdown-toggle {
    border-left-color: $color-primary;
}

body .btn-primary {
    color: $color-primary-inverse;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: $color-primary;
    border-color: $color-primary;
}

body .btn-primary:hover {
    border-color: $color-primary-hover !important;
    background-color: $color-primary-hover;
}

body .btn-primary:active,
body .btn-primary:focus {
    border-color: $color-primary-active !important;
    background-color: $color-primary-active;
}

body .btn-primary[disabled] {
    border-color: $color-primary-disabled !important;
    background-color: $color-primary-disabled;
}

body .btn-primary[disabled]:hover {
    border-color: $color-primary-disabled-hover !important;
    background-color: $color-primary-disabled-hover;
}

body .btn-success {
    color: $color-success-inverse;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: $color-success;
    border-color: $color-success;
}

body .btn-success:hover {
    border-color: $color-success-hover !important;
    background-color:$color-success-hover;
}

body .btn-success:active,
body .btn-success:focus {
    border-color: $color-success-active !important;
    background-color: $color-success-active;
}

body .btn-success[disabled] {
    border-color: $color-success-disabled !important;
    background-color: $color-success-disabled;
}

body .btn-success[disabled]:hover {
    border-color: $color-success-disabled-hover !important;
    background-color: $color-success-disabled-hover;
}

body .btn-warning {
    color: $color-warning-inverse;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: $color-warning;
    border-color: $color-warning;
}

body .btn-warning:hover {
    border-color: $color-warning-hover !important;
    background-color: $color-warning-hover;
}

body .btn-warning:active,
body .btn-warning:focus {
    border-color: $color-warning-active !important;
    background-color: $color-warning-active;
}

body .btn-warning[disabled] {
    border-color: $color-warning-disabled !important;
    background-color: $color-warning-disabled;
}

body .btn-warning[disabled]:hover {
    border-color: $color-warning-disabled-hover !important;
    background-color: $color-warning-disabled-hover;
}

body .btn-danger {
    color: $color-danger-inverse;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: $color-danger;
    border-color: $color-danger;
}

body .btn-danger:hover {
    border-color: $color-danger-hover !important;
    background-color: $color-danger-hover;
}

body .btn-danger:active,
body .btn-danger:focus {
    border-color: $color-danger-active !important;
    background-color: $color-danger-active;
}

body .btn-danger[disabled] {
    border-color: $color-danger-disabled !important;
    background-color: $color-danger-disabled;
}

body .btn-danger[disabled]:hover {
    border-color: $color-danger-disabled-hover !important;
    background-color: $color-danger-disabled-hover;
}

body .btn-info {
    color: $color-info-inverse;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    background-color: $color-info;
    border-color: $color-info;
}

body .btn-info:hover {
    border-color: $color-info-hover !important;
    background-color:$color-info-hover;
}

body .btn-info:active,
body .btn-info:focus {
    border-color: $color-info-active !important;
    background-color: $color-info-active
}

body .btn-info[disabled] {
    border-color: $color-info-disabled !important;
    background-color: $color-info-disabled;
}

body .btn-info[disabled]:hover {
    border-color: $color-info-disabled-hover !important;
    background-color: $color-info-disabled-hover;
}

// Checkboxes
.checkbox-inline .fa {
    position: relative;
    top: -2px;
}

hr.checkbox-radio-inline {
    margin-top: 10px;
    margin-bottom: 10px;
}

.checkbox-primary label:before {
    background: $color-primary;
    border-color: $color-primary;
}

.checkbox-text-primary input[type="checkbox"]:checked + label:after {
    color: $color-primary;
}

// Inputs
input[type="number"] {
    text-align: right;
}

.input-daterange .input-group-addon:first-child {
    border-left-width: 1px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.input-daterange .input-group-addon.dropdown-toggle,
.input-daterange .input-group-addon:last-child {
    border-right-width: 1px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

// Radio Buttons
.radio-primary input[type="radio"]:checked + label:after {
    background: $color-primary;
    box-shadow: 0 0 1px $color-primary;
}

// Selects
// Regular selects based on select2-bootstrap.css
select,
select[multiple] {
    height: 34px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    background-color: $color-primary-inverse;
    background-image: none;
    color: $color-muted;
    padding: 6px 12px;
    font-size: 14px;
    text-overflow: ellipsis;
    line-height: 1.42857143;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

select#month,
select#packageType,
select#status,
select#to_month,
select#to_year,
select#year {
    width: auto;
}

select:focus {
    outline: 0;
}

// MultiSelect
.multiselect-container .input-group {
    width: 100%;
}

.multiselect.selected {
    box-shadow: 0 0 10px $color-primary;
}

.multiselect.disabled,
.multiselect.disabled:hover {
    background-color: $color-primary-disabled;
    color: inherit;
}

button.multiselect {
    text-align: left;
    color: $color-black;
    cursor: default;
}

button.multiselect .caret {
    position: absolute;
    top: 14px;
    right: 10px;
    color: $color-muted;
}

// Select2
.select2-container-multi .select2-choices .select2-search-choice {
    background: $color-primary;
}
.select2-container--bootstrap {
    display: inline-block;
}

@media (max-width: 991px) {
    .select2-container--bootstrap {
        width: 100% !important;
    }
}

.input-group .select2-container--bootstrap:nth-child(2) .select2-selection--single {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group .select2-container--bootstrap:last-child .select2-selection--single {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.select2-container--bootstrap .select2-results__option--highlighted[aria-selected] {
    background-color: $color-primary;
}

.select2-container--bootstrap .select2-dropdown {
    border-color: $color-primary-hover;
}

.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
    border-color: $color-primary-hover;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba($color-primary-hover, 0.6);
}

.input-required {
    border: 1px solid red;
}

.bold {
	font-weight: 700;
}
