#creat-verify-form{
    .code-input {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        font-size: 20px;
        font-weight: bold;
        border: 1px #ccc solid;
        color: #777;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
    }

    #emailAdress::placeholder {
        font-style: italic;
    }

    .tab-content {
        box-sizing: content-box;
        padding-bottom: 35px;
    }

    .mb-20 {
        margin-bottom: 20px !important;
    }

    .mb-15 {
        margin-bottom: 15px !important;
    }

    .mb-10 {
        margin-bottom: 10px !important;
    }
    .mb-30 {
        margin-bottom: 30px !important;
    }
    .nav .active a {
        color: white !important;
        font-weight: bold !important;
    }

    .d-flex {
        display: flex;
    }

    .justify-center {
        justify-content: center;
    }

    .mt-50 {
        margin-top: 50px !important;
    }

    .mt-70 {
        margin-top: 70px !important;
    }
    .mr-50 {
        margin-right: 50px !important;
    }
}

.smartbanner {
    background-color: #fff;

    .smartbanner__button {
        border-color: #ff4200 !important;
        background-color: #ff4200;
        padding: 6px 6px;
        margin-bottom: 0;
        font-size: 14px;
        color: #fff;
    }
}


