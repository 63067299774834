// Courier Activity
#courier-activity-loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Parcel Summary
.parcel-summary-chart-container {
    height: 352px;
}

.parcel-summary-chart-body {
    height: 100%;
}

#parcel-summary-chart {
    height: 100%;
}

@media (max-width: 991px){
    .parcel-summary-chart-container {
        height: auto;
    }

    .parcel-summary-chart-body {
        height: auto;
    }

    #parcel-summary-chart {
        height: auto;
    }
}
