.only-daily {
    float: none;
    margin: 0 auto;
}

.skip-payment {
    background-color: #fff !important;
}

.fee-information-list {
    .fee-item {
        .panel-heading {
            margin: 0 !important;
            padding: 10px 15px !important;
            background-color: $color-primary !important;

            .panel-title {
                color: #fff;
                text-transform: capitalize;
            }
        }

        .panel-body {
            padding: 10px 15px;
            border: 1px solid $color-primary;
            border-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            .price-expression,
            .price-slash {
                font-size: 16px;
                font-weight: bold;
            }

            .price-category {
                font-size: 16px;
            }

            .fee-description{
                font-weight: bold;
            }
        }
    }
}

.fee-information-panel {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .fee-item {
        width: 225px;
        margin-right: 10px;
        margin-left: 10px;
        border: 1px solid $color-primary;
        .panel-heading {
            margin: 0 !important;
            padding: 10px 15px !important;
            background-color: $color-primary !important;
            border-radius: unset;
            .panel-title {
                color: #fff;
                text-transform: capitalize;
            }
        }

        .panel-body {
            padding: 10px 15px;
            border-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            box-shadow: unset;
            .price-expression,
            .price-slash {
                font-size: 16px;
                font-weight: bold;
            }

            .price-category {
                font-size: 16px;
            }

            .fee-description {
                font-weight: bold;
            }
        }
    }
}