.modal-xl-custom {
    max-width: 1100px;
    width: 80%;
}

#modal-terms-of-use-content {
    height: 400px;
    border-radius: 0;
    margin-bottom: 5px;
    padding: 50px !important;
    overflow-y: scroll;
}

.modal-block-primary .fa {
    color: $color-primary;
}

.modal-block-primary.modal-header-color .panel-heading {
    background-color: $color-primary;
}

.modal-block-primary.modal-full-color .panel {
    background-color: $color-primary;
}

.modal-block-primary.modal-full-color .panel-heading {
    background-color: $color-primary;
}

.modal-block-primary.modal-full-color .panel-footer {
    background-color: $color-primary
}

.modal-block-primary .modal-icon .fa {
    color: $color-primary;
}

.modal-body .tabs:last-child {
    margin-bottom: 0;
}

.modal-text p:first-child {
    margin-top: -7px;
}

.modal-loading {
	font-size: 25px;
	text-align: center;
}

.modal-error {
	text-align: center;
}

.modal-fit-content {
    width: fit-content;
}

.ph-modal-content {
    padding: 8px;
}

.ph-modal-open-box-text {
    text-align: center;
    margin: 8px 16px;
}

.ph-modal-open-box-button-group {
    text-align: end;
}

#ph-open-box-modal-loading {
    font-size: 20px;
    display: flex;
    width: 300px;
    margin-top: 30px;
    justify-content: center;
}

.mfp-bg {
	opacity: 0.5;
}

#tos-modal .modal-dialog,
.modal-content {
    height: 80%;
}

#tos-modal .modal-body {
    max-height: calc(100% - 120px);
    overflow-y: scroll;
}

#tos-modal .modal-dialog {
    margin-top: 10%;
}