#add-credit-card {
    .cc-info-wrap {
        padding-right: 0;
        padding-left: 10px;
    }
    .cc-icons {
        display: flex;
    }
    .invalid {
        border-color: $color-primary;
    }
    .cc-info-instruction {
        font-style: italic;
        padding-top: 10px;
    }
    .authorize-net {
        .fa {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #bdbdbd;
        }
    }
    .stripe {
        .cc-info {
            padding-top: 8px;
            padding-left: 15px;
        }
        .fa {
            position: absolute;
            right: 10px;
            top: 10px;
            color: #bdbdbd;
        }
    }
    
    .btn-primary {
        background-color: #FF3300;
        border: 2px solid #FF3300;
        border-radius: 0px;
    }
    
    .btn-primary:hover {
        background-color: #FFFFFF;
        color: #FF3300;
        border: 2px solid #FF3300;
    }

    .center {
        display: flex;
        justify-content: center;
        margin: auto;
    }

    .right {
        display: flex;
        justify-content: right;
        margin: auto;
    }
    
    .card {
        background-color: #333333;
        font-size: 2em;
        color: #FFFFFF;
        width: 327px;
        height: 205px;
        margin: 25px auto;
        border-radius: 16px;
        display: flex;
        align-items: center;
    }

    .deletePaymentButton {
        margin-right: 20px;
    }
}