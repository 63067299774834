$sizes: 5px,
10px,
15px,
20px;

@each $size in $sizes {
    .m-#{$size} {
        margin: $size !important;
    }

    .mt-#{$size} {
        margin-top: $size !important;
    }

    .mb-#{$size} {
        margin-bottom: $size !important;
    }

    .ml-#{$size} {
        margin-left: $size !important;
    }

    .mr-#{$size} {
        margin-right: $size !important;
    }

    .p-#{$size} {
        padding: $size !important;
    }

    .pt-#{$size} {
        padding-top: $size !important;
    }

    .pb-#{$size} {
        padding-bottom: $size !important;
    }

    .pl-#{$size} {
        padding-left: $size !important;
    }

    .pr-#{$size} {
        padding-right: $size !important;
    }
}
