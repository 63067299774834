// Couriers
.access-code {
    display: inline-block;
}

.checkbox-courier-charge {
    margin-top: 35px;
}

// FTP Importer
#property-code-group {
    .form-group:last-child,
    .form-group:last-of-type {
        margin-bottom: 15px;
    }
}

// Notifications
.notifications-menu {
    width: 100%;
    margin: auto;
    padding-left: 0;
    padding-right: 0;
    list-style: none;
}

// Outbound Packages
.outbound-sections {
    padding: 15px;
}

// Resident Groups
.delete-row {
    cursor: pointer;
}

#property-alternate-address {
    margin-bottom: 15px;
}

#residentgroups h4:not(:first-of-type),
#storageandregistrationfees h5,
#businesshours h5 {
    margin-top: 30px;
}

.table-fee tbody td:nth-child(1) {
    width: 4%;
}

.table-fee tbody td:nth-child(2) {
    width: 12%;
}

.table-fee tbody td:nth-child(3) {
    width: 8%;
}

.table-fee tbody td:nth-child(4) .table-fee tbody td:nth-child(5) {
    width: 12%;
}

.table-fee tbody td:nth-child(6) {
    width: 6%;
}

.table-fee tbody td:nth-child(7) {
    width: 18%;
}

.table-fee tbody td:nth-child(8),
.table-fee tbody td:nth-child(9) {
    width: 14%;
}

.table-inline-edit thead th:first-child,
.table-inline-edit thead th:last-child,
.table-inline-edit tbody td:first-child,
.table-inline-edit tbody td:last-child {
    width: 10%;
}

.table-inline-edit .help-block {
    margin-bottom: 0;
}

.table-inline-edit tbody td:last-child {
    vertical-align: top !important;
    padding-top: 15px;
}

#table-user-sub-group tbody td:nth-child(2) {
    width: 25%;
}

#table-user-sub-group tbody td:nth-child(3) {
    width: 55%;
}

#table-importer-credential-entrata thead th:nth-child(2),
#table-importer-credential-entrata tbody td:nth-child(2) {
    width: 11%;
}

#table-importer-credential-entrata thead th:nth-child(3),
#table-importer-credential-entrata thead th:nth-child(4),
#table-importer-credential-entrata thead th:nth-child(5),
#table-importer-credential-entrata tbody td:nth-child(3),
#table-importer-credential-entrata tbody td:nth-child(4),
#table-importer-credential-entrata tbody td:nth-child(5) {
    width: 23%;
}

#table-importer-credential-realpage thead th:nth-child(2),
#table-importer-credential-realpage thead th:nth-child(3),
#table-importer-credential-realpage tbody td:nth-child(2),
#table-importer-credential-realpage tbody td:nth-child(3) {
    width: 40%;
}

#table-importer-credential-yardi thead th:nth-child(2),
#table-importer-credential-yardi tbody td:nth-child(2) {
    width: 10%;
}

#table-importer-credential-entrata thead th:nth-child(3),
#table-importer-credential-entrata thead th:nth-child(4),
#table-importer-credential-entrata thead th:nth-child(5),
#table-importer-credential-entrata thead th:nth-child(6),
#table-importer-credential-entrata thead th:nth-child(7),
#table-importer-credential-entrata tbody td:nth-child(3),
#table-importer-credential-entrata tbody td:nth-child(4),
#table-importer-credential-entrata tbody td:nth-child(5),
#table-importer-credential-entrata tbody td:nth-child(6),
#table-importer-credential-entrata tbody td:nth-child(7) {
    width: 12%;
}

table.datatable-locker-video thead th:nth-child(5),
table.datatable-locker-video thead th:nth-child(6),
table.datatable-locker-video tbody td:nth-child(5),
table.datatable-locker-video tbody td:nth-child(6) {
    width: 25%;
}

table.table-notifications thead th:nth-child(1),
table.table-notifications tbody td:nth-child(1) {
    width: 10%;
}

table.table-notifications thead th:nth-child(2),
table.table-notifications tbody td:nth-child(2) {
    width: 15%;
}

table.table-notifications thead th:nth-child(3),
table.table-notifications tbody td:nth-child(3) {
    width: 55%;
}

table.table-notifications thead th:nth-child(4),
table.table-notifications tbody td:nth-child(4) {
    width: 20%;
}

div.table-resident-import-wrapper {
    max-height: 300px;
    overflow: scroll;
}

input.check-unit,
input.check-import-unit-list {
    margin-left: 30px !important;
}

.signin-header {
    font-size: 2em;
    margin-bottom: 1.5em;
}

.signin-text-content {
    color: #000000;
    margin-bottom: 4em;
}

.signin-link {
    margin-bottom: 3em;
}

.dropdown-menu > li > a {
    white-space: normal !important;
}

.toggle .active > .accordion-data {
    color: $color-primary-inverse;
}

/* Edit Property Units modals */
#edit-multiple-header {
    min-width: 120px;
}
.edit-multiple {
    display: none;
}
.btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: #343a40;
}
#propertyunits h4 {
    line-height: 4.2rem;
}
#bulk-buttons-outer {
    min-height: 56px;
    top: -18px;
    text-align: right;
}

#bulk-buttons-outer .btn {
    margin-top: 5px;
}

#bulk-buttons-outer #pull-provider-unit-list {
    min-width: 250px;
    right: 0px;
    top: 0px;
}
#bulk-buttons-outer .bulk-edit-buttons {
    width: 100%;
}

#bulk-buttons-outer .bulk-view-buttons {
    display: inline-block;
}

#modal-edit-units .panel-body {
    margin-left: 10%;
}
#modal-edit-units .disabled {
    color: #ccc;
}
#modal-edit-units .smallTitle {
    margin-left: -10px;
}
#modal-edit-units .row {
    margin-bottom: 4px;
}
#units_table i.delete-unit.selected {
    color: red;
}

#modal-dedupe-units .panel-heading {
    background-color: #f78300;
}
#modal-dedupe-units .panel-heading h2 {
    color: white;
}
table.mass-move-header-table {
    width: 90%;
}
table.mass-move-header-table th {
    padding-left: 20px;
    font-size: 1em;
}
#propertyunits h4 {
    line-height: 1rem;
}
#bulk-buttons-outer #pull-provider-unit-list {
    position: relative;
}
span.edit-multiple-default {
    line-height: 20px;
}
button#dedupe-units {
    margin-left: 8px;
}
#resident-table-review tbody tr td {
    word-break: break-word;
}
#div-enable_delay_registration_email {
    label{
        padding-right: 5px;
    }
    display: flex;
    align-items: center;
    #delay_registration_email_day{
        width: 100px;
    }
}
#property-export-fee{
    .ExportFee--udpateForm{
        .modal-body{
            max-height: 600px;
            overflow-y: auto;
            .exported-transaction-header{
                display: flex;
                align-items: center;
                h4 {
                    margin: 0px 10px 0px 0px;
                }
            }
            .exported-transaction-row{
                margin-bottom: 10px;
            }
            #button-add-transaction{
                cursor: pointer;
            }
            .trans-description{
                display: flex;
                align-items: center;
                em{
                    padding: 0 10px;
                    cursor: pointer;
                    color: $color-primary;
                }
            }
            .warning-active-fee{
                padding: 10px 10px;
            }
        }
    }
    .ExportFee--insertForm{
        .modal-body{
            max-height: 600px;
            overflow-y: auto;
            .exported-transaction-header{
                display: flex;
                align-items: center;
                h4 {
                    margin: 0px 10px 0px 0px;
                }
            }
            .exported-transaction-row{
                margin-bottom: 10px;
            }
            #button-add-transaction{
                cursor: pointer;
            }
            .trans-description{
                display: flex;
                align-items: center;
                em{
                    padding: 0 10px;
                    cursor: pointer;
                    color: $color-primary;
                }
            }
            .warning-active-fee{
                padding: 10px 10px;
            }
        }
    }
}


#action_fallback_instruction .message {
    margin-top: 10px;
}

#action_fallback_instruction .message .fa-eye,
#action_fallback_instruction .message .fa-pencil {
    color: #ff8a00;
    padding: 0 5px;
}

#modal-edit-msg-fallback textarea,
#modal-edit-courier-message textarea,
#modal-edit-resident-message textarea {
    width: 100%;
}

#action_fallback_instruction .button-delete-action-fallback {
    align-self: center;
    line-height: 34px;
    padding-bottom: 10px;
}

#action_fallback_instruction .action-delivered.hide ~ .button-delete-action-fallback {
    align-self: flex-end;
    line-height: 34px;
    padding-bottom: 0px;
}

#locker-surveillance-camera {
    #locker-surveillance-camera-form {
        .loading-devives {
            text-align: center;
            height: 40px;

            i {
                font-size: 24px;
                line-height: 40px;
            }
        }
        .panel-body {
            .surveilance-header {
                display: flex;
                align-items: center;

                .surveilance-message {
                    flex-basis: 80%;
                    display: flex;
                    align-items: center;
                    padding: 8px;
                    margin: 0px auto;
                    text-align: center;
                    border: 1px solid #DEC475;
                    background-color: #FFF2CC;

                    i {
                        font-size: 28px;
                        line-height: 26px;
                        font-weight: bold;
                    }
                    
                    div {
                        margin: 0 auto;
                    }
                }
            }

            .row {
                #table-surveillance-camera {
                    thead {
                        th {
                        }
                    } 

                    tbody {
                        tr {
                            background-color: #fff;
                            .model, .serial, td:first-child {
                                width: 15%
                            }
                        }
                    }
                }
            }
        }
    }
}

.mass-btn {
    width: 100%; 
    margin-top:10px;
}
