// Base settings
@import "../config/variables";

// Theme-specific settings
// Save this file in the config directory with the same name, but starting with
// an underscore so it is used as an include only.
@import "config/parcelpendingbyquadient";

// Common settings
@import "config/common";

// Modules
@import "modules/all";
