@media (min-width: 840px){
    .mfp-wrap, .modal-dialog{
        .custom-street-name-with{
            width: 25%;
        }
        .custom-unit-with{
            width: 15%;
        }
    }
}

@media (min-width: 768px) and (max-width:840px) {
    .mfp-wrap, .modal-dialog{
        .custom-street-name-with{
            width: 20%;
        }
        .custom-unit-with{
            width: 16%;
        }
    }
}