// Bootstrap Date Picker
.form-field-input-date:not(.disabled) {
    background: $color-primary-inverse !important;
}

.datepicker table {
    width: 100%;
}

.datepicker table tr td span:hover {
    background: $color-primary;
}

.datepicker table tr td.day:hover {
    background: $color-primary;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
    color: $color-muted !important;
}

.datepicker table tr td.today,
.datepicker table tr td.range.today {
    background-color: $color-primary;
    color: $color-primary-inverse;
}

.datepicker table thead tr th.prev:hover,
.datepicker table thead tr th.next:hover {
    background: $color-primary;
}

.datepicker table thead tr:first-child th:hover {
    background: $color-primary;
}

.datepicker table tbody tr td.day.new {
    color: $color-secondary;
}

.datepicker table tfoot tr th:hover {
    background: $color-primary;
}

.datepicker.datepicker-primary table thead tr:first-child {
    background-color: $color-primary;
}

.datepicker.datepicker-primary table thead tr:first-child th:hover {
    background-color: $color-primary-hover;
}

.datepicker.datepicker-primary table thead tr:last-child {
    background-color: $color-primary;
}

.datepicker.datepicker-primary table thead tr:last-child th:hover {
    background-color: $color-primary-hover;
}

.datepicker.datepicker-primary table tbody tr td.day:hover {
    background: $color-primary;
}

.datepicker.datepicker-primary table tbody tr td.day.active {
    background: $color-primary;
}

// Bootstrap Time Picker
.bootstrap-timepicker-widget table td a:hover {
    background-color: $color-primary;
}

// Dropdown Menu
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
    background-color: $color-primary;
}

.open > .dropdown-toggle.btn-primary {
    background: $color-primary;
    border-color: $color-primary-hover;
}

// FullCalendar
.fc .fc-toolbar h2:before {
    color: $color-primary;
}

.fc .fc-toolbar .fc-button.fc-state-active {
    background-color: $color-primary;
}

.fc-event {
    background: $color-primary;
    border-color: $color-primary;
}

.fc-event.fc-event-primary {
    background: $color-primary;
    border-color: $color-primary;
}

// Liquid Meter
.liquid-meter-wrapper .liquid-meter-selector a.active {
    color: $color-primary;
}

// Lock Screen
.body-locked .current-user .user-image {
    border-color: $color-primary;
}

// Maps
.jqvmap-zoomin,
.jqvmap-zoomout {
    background: $color-primary;
}

// nanoScroller.js
html.no-overflowscrolling .nano > .nano-pane > .nano-slider {
    background: $color-primary;
}

// Nestable
.dd-handle:hover {
    color: $color-primary !important;
}

.dd-placeholder {
    background: $color-info-background;
    border-color: $color-primary;
}

// Notifications
.notifications .notification-menu .notification-title {
    background: $color-primary;
}

.notifications .notification-menu .notification-title .label-default {
    background-color: #ff5300;
}

.notifications .notification-menu:before,
.notifications .notification-icon:before {
    border-bottom-color: $color-primary;
}

// noUiSlider
.noUi-connect {
    background: $color-primary !important;
}

.noUi-handle {
    outline: 0 none !important;
}

// Popovers
span.input-group-addon .popover {
    width: 100%;
}

.popover-title {
    color: $color-default-inverse;
}

.popover {
    .btn {
        margin-right: 5px !important;
    }

    .btn:last-child {
        margin-right: 0 !important;
    }
}

// PNotify
.ui-pnotify {
    z-index: $pnotify-z-index;

    .notification-primary {
        background: rgba($color-primary, $mix-translucency-partial);
    }
}

.ui-pnotify.stack-bar-top .notification-primary,
.ui-pnotify.stack-bar-bottom .notification-primary {
    background: $color-primary;
}

.ui-pnotify.notification-primary .notification,
.ui-pnotify.notification-primary .notification-primary {
    background: rgba($color-primary, $mix-translucency-partial);
}

.ui-pnotify.notification-primary.stack-bar-top .notification,
.ui-pnotify.notification-primary.stack-bar-bottom .notification,
.ui-pnotify.notification-primary.stack-bar-top .notification-primary,
.ui-pnotify.notification-primary.stack-bar-bottom .notification-primary {
    background: $color-primary;
}

// Progress Bars
.progress-bar {
    background-color: $color-primary;
}

.progress .progress-bar-primary {
    background-color: $color-primary;
}

// Progress Bars (Loading)
#nprogress {
    .bar {
        background: $color-primary;
    }

    .peg {
        box-shadow: 0 0 10px 1px $color-primary, 0 0 5px $color-primary;
    }
}

// Scrollable
.scrollable.colored-slider .scrollable-slider {
    background: $color-primary;
}

// Simple List
ul.simple-bullet-list li:before {
    border-color: $color-primary;
}

// Simple Card List
.simple-card-list li.primary {
    background: $color-primary;
}

// Slider
.slider-primary .ui-slider-range,
.slider-primary .ui-slider-handle {
    background: $color-primary;
}

.slider-gradient.slider-primary .ui-slider-range,
.slider-gradient.slider-primary .ui-slider-handle {
    background-image: linear-gradient(left, $color-primary-disabled 0, $color-primary 100%);
}

.slider-gradient.ui-slider-vertical.slider-primary .ui-slider-range,
.slider-gradient.ui-slider-vertical.slider-primary .ui-slider-handle {
    background-image: linear-gradient(to right, $color-primary-disabled 0, $color-primary 100%);
}

// Social Icons
.social-icons-list a {
    background: $color-primary;
}

// Switch
.switch.switch-primary .ios-switch .on-background {
    background: $color-primary;
}

// Thumb Info
.thumb-info .thumb-info-type {
    background-color: $color-primary;
}

// Timeline
.timeline .tm-items > li .tm-datetime .tm-datetime-time {
    color: $color-primary;
}

.timeline .tm-items > li .tm-icon {
    border-color: $color-primary;
    color: $color-primary;
}

.timeline.timeline-simple .tm-body .tm-items > li:before {
    background: $color-primary;
    box-shadow: 0 0 0 3px $color-primary-inverse, 0 0 0 6px $color-primary;
}

// Toggle
.toggle label {
    border-left-color: $color-primary;
    color: $color-primary;
}

// Undo the colors for labels inside our multi-select plugin
.toggle .multiselect-container label {
    color: unset;
}

.toggle.active {
    & > label {
        background: $color-primary !important;
        border-color: $color-primary;

        .text-default {
            color: $color-default-dark !important;
        }

        .text-primary {
            color: $color-primary-dark !important;
        }

        .text-success {
            color: $color-success-dark !important;
        }

        .text-warning {
            color: $color-warning-dark !important;
        }

        .text-danger {
            color: $color-danger-dark !important;
        }

        .text-info {
            color: $color-info-dark !important;
        }

        .text-secondary {
            color: $color-secondary-dark !important;
        }
    }
}

// Tooltip
.tooltip {
    z-index: 20000;
    font-family: $font-primary;
    font-size: 1.1rem;
}

// Treeview
.jstree-default {
    .jstree-hovered {
        background-color: $color-info-background !important;
    }

    .jstree-clicked {
        background-color: $color-info-hover !important;
    }

    .colored {
        color: $color-primary !important;

        .jstree-icon {
            color: $color-primary !important;
        }
    }

    .jstree-default .colored-icon .jstree-icon {
        color: $color-primary !important;
    }
}

// Widgets
.sidebar-widget.widget-tasks ul li:before {
    border-color: $color-primary;
}

.widget-twitter-profile {
    background-color: $color-primary;

    .profile-quote {
        background-color: $color-primary;

        .quote-footer {
            border-top-color: rgba($color-primary, 0.7);
        }
    }
}

.widget-profile-info .profile-info .profile-footer {
    border-top-color: rgba($color-primary, 0.7);
}

.widget-summary .summary {
    word-break: normal;
}

.widget-summary.widget-summary-md .summary-footer {
    display: block;
    font-size: 1.0rem;
    line-height: 1.5rem;
}

.widget-summary-col .title a {
    color: $color-black;
}

.bg-primary .widget-summary .summary .title a,
.bg-secondary .widget-summary .summary .title a,
.bg-tertiary .widget-summary .summary .title a,
.bg-quaternary .widget-summary .summary .title a {
    color: $color-primary-inverse;
}

.widget-summary .summary-footer a {
    color: $color-default-inverse;
}

// Zoom
.img-thumbnail .zoom {
    background: $color-primary;
}

//button of OneTrust for cookies setting
.application-footer #ot-sdk-btn.cookie-setting-btn.ot-sdk-show-settings {
    color: $color-primary;
    border: none;
    background: none;
    font-size: 13px;
    padding: unset;
}

.application-footer #ot-sdk-btn.cookie-setting-btn.ot-sdk-show-settings:hover {
    color: $color-primary-hover;
    text-decoration-color: $color-primary-hover;
    text-decoration: underline;
}

.application-footer #ot-sdk-btn.cookie-setting-btn.ot-sdk-show-settings:focus {
    outline: none;
}