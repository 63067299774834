// =============================================================================
// BS CONFIG FOR NEW GRID TIER
// =============================================================================
$screen-lg: 1200px;
$screen-xl: 1600px;

$screen-lg-max: $screen-xl - 1;

$grid-gutter-width: 30px;

// =============================================================================
// TYPOGRAPHY
// =============================================================================
$font-primary: sans-serif;
$font-secondary: cursive;

$root-font-size: 10;
$body-font-size: 13;

$title-decrement-font-size: 6;

$h1-font-size: 36;
$h2-font-size: $h1-font-size - $title-decrement-font-size;
$h3-font-size: $h2-font-size - $title-decrement-font-size;
$h4-font-size: $h3-font-size - $title-decrement-font-size;
$h5-font-size: $h4-font-size - 4;
$h6-font-size: 12;

// weights
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// spacement
$spacement-increment: 5px;
$spacement-xs: $spacement-increment;
$spacement-sm: $spacement-xs + $spacement-increment;
$spacement-md: $spacement-sm + $spacement-increment;
$spacement-lg: $spacement-md + $spacement-increment;
$spacement-xl: $spacement-lg + $spacement-increment;
$spacement-xlg: $spacement-xl + $spacement-increment;

// borders
$border-thin: 3px;
$border-normal: 5px;
$border-thick: 7px;

// =============================================================================
// COLORS
// =============================================================================
$color-default: #ebebeb;
$color-default-inverse: #777;

$color-muted: #999;

$color-primary: #CCC;
$color-primary-inverse: #FFF;

$color-success: #47a447;
$color-success-inverse: #FFF;

$color-warning: #ed9c28;
$color-warning-inverse: #FFF;

$color-danger: #d2322d;
$color-danger-inverse: #FFF;

$color-info: #5bc0de;
$color-info-inverse: #FFF;

$color-secondary: #E36159;
$color-secondary-inverse: #FFF;

$color-tertiary: #2BAAB1;
$color-tertiary-inverse: #FFF;

$color-quaternary: #734BA9;
$color-quaternary-inverse: #FFF;

$color-dark: #171717;
$color-dark-inverse: #FFF;

$body-color: #ecedf0;
$sidebar-color: #33363F;

$color-black: #333;

// colors list
$colors-list: (primary $color-primary $color-primary-inverse) (secondary $color-secondary $color-secondary-inverse) (tertiary $color-tertiary $color-tertiary-inverse) (quaternary $color-quaternary $color-quaternary-inverse) (success $color-success $color-success-inverse) (warning $color-warning $color-warning-inverse) (danger $color-danger $color-danger-inverse) (info $color-info $color-info-inverse) (dark $color-dark $color-dark-inverse);

// states list
$states: (primary $color-primary $color-primary-inverse) (success $color-success $color-success-inverse) (warning $color-warning $color-warning-inverse) (danger $color-danger $color-danger-inverse) (info $color-info $color-info-inverse) (dark $color-dark $color-dark-inverse);

// Dark Version
$dark-bg: #1d2127;
$dark-default-text: #808697;

$dark-color-1: $dark-bg;
$dark-color-2: lighten($dark-color-1, 2%);
$dark-color-3: lighten($dark-color-1, 5%);
$dark-color-4: lighten($dark-color-1, 8%);
$dark-color-5: lighten($dark-color-1, 3%);

$darken-color-1: darken($dark-color-1, 2%);



// =============================================================================
// LAYERS Z-INDEX
// =============================================================================
$sidebar-left-z-index: 1010;
$sidebar-right-z-index: 1010;
$header-z-index: 1000;
$header-fixed-z-index: 1020;
$page-header-z-index: 1001;
$inner-toolbar-z-index: 1001;
$inner-menu-toggle-z-index: 1002;
$inner-menu-z-index: 1002;
$modal-z-index: 1100;
$magnific-popup-z-index: 10000;

// =============================================================================
// HEADER
// =============================================================================
// sizes
$header-height: 60px;

	// notification menu
	// -----------------
	// padding
	$header-notification-menu-padding: 12px;

	// width
	$header-notification-menu-width: 245px;

	// notification menu - title
	// -------------------------
	// background
	$header-notification-menu-title-background: $color-primary;

	// border
	$header-notification-menu-border-radius: 3px 3px 0 0;

	// colors
	$header-notification-menu-title-color: #FFF;

	// font size
	$header-notification-menu-title-size: 11;

	// padding
	$header-notification-menu-title-padding: 8px 6px 8px 12px;

	// label
	$header-notification-menu-label-background: #006697;

	// notification menu - pin
	// -----------------------
	$header-notification-menu-pin-width: 6px;

	// notification menu - hr
	// ----------------------
	$header-notification-menu-hr-background: #E6E6E6;
	$header-notification-menu-hr-margin: 12px 0;

	// notification menu - view more
	$header-notification-menu-view-more-color: #ACACAC;
	$header-notification-menu-view-more-size: 11;

		// notification menu - emails
		// --------------------------
		// name
		$notification-menu-email-name-color: #000011;
		$notification-menu-email-name-padding: 2px 0 0;
		$notification-menu-email-name-size: 13;

		// message
		$notification-menu-email-message-color: #ACACAC;
		$notification-menu-email-message-size: 11;
		$notification-menu-email-message-padding: 0;

// =============================================================================
// SIDEBAR LEFT
// =============================================================================
// classes
$sidebar-left-collapsed-class: 'sidebar-left-collapsed';

// default
$sidebar-left-full-width: 300px;

// small size - sm
$sidebar-left-sm-full-width: 250px;

// extra small size - xs
$sidebar-left-xs-full-width: 200px;

// collapsed
$sidebar-left-collapsed-width: 73px;

// top header size
$sidebar-top-header-size: 50px;

// background
$sidebar-background: #1D2127;

// border color
$sidebar-border-color: #2F3139;

// border width
$sidebar-border-width: 5px;

// toggle background color
$sidebar-toggle-background: #171717;

// toggle hover color
$sidebar-toggle-color: #C3C3C3;

// toggle hover color
$sidebar-toggle-hover-color: $color-primary;

// toggle width
$sidebar-toggle-width: $sidebar-left-collapsed-width;

// toggle bottom radius
$sidebar-toggle-bottom-radius: 5px;

// =============================================================================
// SIDEBAR RIGHT
// =============================================================================
// classes
$sidebar-right-opened-class: 'sidebar-right-opened';

// showing
$sidebar-right-width: $sidebar-left-full-width;

// background
$sidebar-right-background: #171717;

// =============================================================================
// PAGE HEADER
// =============================================================================
// background
$page-header-background: #171717;

// borders
$page-header-border-bottom-width: 4px;
$page-header-border-left-width: 1px;
$page-header-border-bottom-color: #CCCCCC;
$page-header-border-left-color: #3A3A3A;

// colors
$page-header-color: #C3C3C3;

// height
$page-header-height: 50px;

	// title
	$page-header-title-border-color: $color-primary;
	$page-header-title-font-size: 20;
	$page-header-title-padding: 20px;

	// breadcrumb
	$page-header-breadcrumb-home-size: 20;
	$page-header-breadcrumb-separator-size: 14;
	$page-header-breadcrumb-separator-margin: 0 10px;
	$page-header-breadcrumb-size: 14;

	// toggle
	$page-header-sidebar-toggle-margin: 0 0 0 10px;
	$page-header-sidebar-toggle-hover-color: $sidebar-toggle-hover-color;

// =============================================================================
// CONTENT WITH MENU
// =============================================================================
$inner-menu-width: 300px;
$inner-menu-toggle-width: 140px;
$inner-toolbar-height: 52px;

// =============================================================================
// SETTINGS
// =============================================================================
$border-radius: 5px;

// =============================================================================
// CONTENT BODY
// =============================================================================
$content-body-padding: 40px;

// =============================================================================
// MENU
// =============================================================================
// backgrounds
$menu-item-active-background: darken($sidebar-background, 2%);

// borders
$menu-item-active-border-color: $color-primary;
$menu-item-active-border-width: 2px;
$menu-item-border-color: #2D3138;
$menu-children-border-color: rgba(69,74,84,0.7);

// colors
$menu-item-text-color: #abb4be;
$menu-item-arrow-color: #abb4be;

// paddings
$menu-item-padding-horizontal: 25px;
$menu-item-padding-vertical: 12px;
$menu-item-padding: $menu-item-padding-vertical $menu-item-padding-horizontal;


$menu-children-padding-horizontal: 15px;
$menu-children-padding-vertical: 6px;
$menu-children-padding: $menu-children-padding-vertical $menu-children-padding-horizontal $menu-children-padding-vertical ($menu-children-padding-horizontal + 42);
$menu-children-level1-padding: $menu-children-padding-vertical $menu-children-padding-horizontal $menu-children-padding-vertical ($menu-children-padding-horizontal + 67);
$menu-children-level2-padding: $menu-children-padding-vertical $menu-children-padding-horizontal $menu-children-padding-vertical ($menu-children-padding-horizontal + 82);

$menu-item-arrow-padding: 14px 25px;
$menu-item-children-arrow-padding: 6px 25px;

// =============================================================================
// SIDEBAR WIDGETS
// =============================================================================
// tasks
$widget-tasks-colors: #D64B4B, #0090D9, #4DD79C, #D9A300, #C926FF, #FFFF26;

// =============================================================================
// PANELS
// =============================================================================
// heading
$panel-heading-background: #f6f6f6;
$panel-heading-padding: 18px;

	// title
	$panel-title-color: #33353F;
	$panel-title-size: 20px;

	// subtitle
	$panel-subtitle-color: #808697;
	$panel-subtitle-size: 12px;

	// panel actions
	$panel-action-background: transparent;
	$panel-action-border-radius: 2px;
	$panel-action-color: #B4B4B4;
	$panel-action-size: 14px;
	$panel-action-height: 24px;
	$panel-action-width: 24px;

	$panel-action-color-hover: $panel-action-color;
	$panel-action-background-hover: darken($panel-heading-background, 3%);

// featured
$panel-featured-border-width: 3px;

// =============================================================================
// TABS
// =============================================================================
$tabs-border-color: #EEE;

// nav
// -----------------------------------------------------------------------------
	// default color
	$tabs-nav-default-color: #555;

	// background
	$tabs-nav-item-background: #F4F4F4;
	$tabs-nav-item-background-active-hover: #FFF;

	// borders
	$tabs-nav-border-radius: 8px;
	$tabs-nav-item-border-color: $tabs-border-color;
	$tabs-nav-item-border-top: 3px;
	$tabs-nav-item-border-top-color: #DDD;

// wizard
// -----------------------------------------------------------------------------
	// colors
	$wizard-nav-default-color: $tabs-nav-default-color;
	$wizard-nav-disabled-color: #CCC;

	$wizard-progress-item-color: #33333F;
	$wizard-progress-default-color: #FFF;
	$wizard-progress-completed-color: #FFF;
	$wizard-progress-active-color: $color-primary;

	// background
	$wizard-nav-item-background: $tabs-nav-item-background;
	$wizard-nav-item-background-active-hover: #FFF;
	$wizard-nav-item-chevron-border-color: #E0E0E0;

	$wizard-progress-default-background: #CCC;
	$wizard-progress-completed-background: $color-primary;
	$wizard-progress-active-background: #FFF;

	// borders
	$wizard-nav-item-border-color: $tabs-border-color;

	$wizard-progress-default-border-color: #CCC;
	$wizard-progress-completed-border-color: $color-primary;
	$wizard-progress-active-border-color: $color-primary;


// content
// -----------------------------------------------------------------------------
	// background
	$tabs-content-background: #FFF;

	// borders
	$tabs-content-border-radius: 4px;
	$tabs-content-border-color: $tabs-border-color;

	// box shadow
	$tabs-content-box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.04);

	// padding
	$tabs-content-padding: 15px;

// =============================================================================
// PROGRESS BARS
// =============================================================================

	// sizes
	$pb-xs: 7px;
	$pb-sm: 12px;
	$pb-md: 14px;
	$pb-lg: 16px;
	$pb-xl: 18px;

	// background
	$pb-default: #474453;
	$pb-default-line: #585564;
	$pb-light: #f5f5f5;
	$pb-light-line: #F6F7F8;


// =============================================================================
// FORM CONTROL
// =============================================================================

	// borders
	$form-control-border-color: #66afe9;

	// box shadow
	$form-control-box-shadow: inset 0 1px 1px rgba(#000,.075), 0 0 8px rgba($form-control-border-color, 0.6);
