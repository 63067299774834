section.panel .pull-right .btn {
    position: relative;
    top: -6px;
}

section.panel .panel-heading .panel-actions {
    width: 25%;

    a {
        float: right;
    }
}

div.panel .panel-heading[data-panel-toggle] {
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .panel-actions {
        float: none;
        position: absolute;
    }

    section.panel .panel-heading .panel-actions {
        width: 50%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    section.panel .panel-heading .panel-actions {
        width: 40%;
    }
}

@media only screen and (min-width: 992px) and (max-width: $screen-md-max) {
    section.panel .panel-heading .panel-actions {
        width: 30%;
    }
}

.panel-body h1:first-child,
.panel-body h2:first-child,
.panel-body h3:first-child {
    margin-top: 0;
}

.panel-body > div.tabs,
.panel-body > div.well {
    margin-bottom: 0;
}

.panel-body > div.tabs .toggle {
    margin-bottom: 15px;
}

.panel-body p:last-child {
    margin-bottom: 0;
}

.panel-body iframe {
    margin: 0;
    border: none;
    width: 100%;
    height: 300px;
}

footer.panel-footer button {
    margin-left: 10px;
}
