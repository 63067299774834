// Theme Directories
$theme-base-directory: parcelpendingbyquadient;

// Typography
$font-primary: "Open Sans", sans-serif;

// Colors
$color-primary: #ff4200;
$color-secondary: #000000;
$color-muted: #a9a9af;

$color-default-inverse: #000000;
$color-success: #71bf44;
$color-info: #05b9f0;
$sidebar-toggle-color: #3f4443;
$page-header-color: #3f4443;
$panel-title-color: #000000;

$color-chart-usage-1: $color-muted;
$color-chart-usage-2: mix(white, $color-primary, 40);
$color-chart-usage-3: $color-primary;
$color-chart-usage-4: $color-secondary;
$color-chart-usage-5: mix(white, $color-secondary, 40);

$color-menu: mix(black, $color-secondary, 50);
$color-menu-current: $color-menu;
$color-menu-hover: $color-menu;
$color-menu-inverse: $color-secondary-inverse;
$color-menu-sub-active: $color-primary;
// Layout Fixes
$form-filter-heading-margin-top: 28px;

// Backgrounds
body#login {
    background-image: url("/img/themes/" + $theme-base-directory + "/background.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

body {
    background-color: #ffffff !important;
    color: #3f4443 !important;
}

.fa-star, .fa-plus-circle{
    color: #05b9f0 !important;
}

html .scroll-to-top {
    background: #000000 !important;
}

.sidebar-left .sidebar-header .sidebar-toggle i {
    color: $sidebar-toggle-color !important;
}

.page-header .breadcrumbs li,
.page-header .breadcrumbs a,
.page-header .breadcrumbs span {
    color: $sidebar-toggle-color !important;
}

.content-body #content .panel .panel-heading {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    margin-bottom: -1px;
}

.content-body #content .panel .panel-heading .panel-title {
    color: $panel-title-color;
}

.userbox .name {
    color: #000000;
}

.userbox .role {
    color: #3f4443;
}

h3 small,
.h3 small,
h3 .small,
.h3 .small {
    color: $panel-title-color;
}

#creat-verify-form .nav .active a {
    background-color: $color-primary !important;
}

.badge-danger {
    background-color: $color-primary !important;
}

#modal-bulk-move-users .panel-heading,
#modal-occupant-add .panel-heading,
#modal-add-unit .panel-heading,
#modal-dedupe-units .panel-heading,
.modal-header {
    color: #ffffff;
    background-color: $color-primary;
    border: none;
}

#modal-bulk-move-users .panel-title,
#modal-occupant-add .panel-title,
#modal-add-unit .panel-title,
#modal-dedupe-units .panel-title {
    color: #ffffff;
}

#content .panel-body {
    background: #ffffff;
    border: 1px solid #e6e6e6;
}

.panel-footer {
    background-color: #ffffff;
    border: 1px solid #e6e6e6;
}

ul.nav-main li .nav-children {
    background: #ffffff;
    box-shadow: 0px -3px 3px -3px #e6e6e6 inset;
}
html body .tabs-primary .nav-tabs li a, html body .tabs-primary .nav-tabs.nav-justified li a{
    background-color: #ffffff;
}

html body .tabs-primary .nav-tabs li.active a,
html body .tabs-primary .nav-tabs.nav-justified li.active a {
    color: #ffffff !important;
    background-color: $color-primary !important;
}

html body #modal-courier-add .tabs-primary .nav-tabs.nav-justified li.active a {
    background-color: #fff !important;
    color: $color-primary !important;
}
html .panel-warning .panel-heading {
	background: #ff4200;
}

#content .panel-primary .panel-heading {
    background-color: $color-primary !important;
}

#content .panel-primary .panel-heading .panel-title,
.fee-information-panel .panel-title {
    color: #ffffff !important;
}

.fee-information-panel .panel-heading,
.fee-information-panel .panel-body {
    border: unset !important;
}

#sidebar-left {
    border-right: 1px solid #e6e6e6;
}

.toggle label {
    background: #fff;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
}
.toggle .form-group label, .toggle .toggle-content label {
    border: unset;
}
.toggle .multiselect-container .active .checkbox{
    background-color: $color-primary;
}
