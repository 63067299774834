#existing-account{
    .description{
        padding: 35px 0px 48px 0px;
    }
    .action{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
        a{
            padding: 10px 20px;
        }
        .continue{
            margin-left: 50px;
        }
    }
}