// Login and Registration Layout
body#login {
    .panel-body {
        .panel-heading {
            margin: -33px -33px 10px -33px;
            padding: 18px 33px;
            background: #f6f6f6;
        }

        .validation-message {
            background: #FFEFEF;

            ul {
                margin-left: -33px;
                margin-right: -33px;
                padding-left: 33px;
                padding-right: 33px;
            }
        }
    }
    .custom-padding {
        padding-left: 0;
    }
}

body#login .body-sign-lg .panel-body div.validation-message ul {
    margin-top: -33px;
}

div.validation-confirmation ul {
    border-bottom: 1px solid #cbffcb;
    background: #efffef;
}

.body-sign .center-sign {
    padding-top: 50px;
}

.body-sign .panel-sign .panel-title-sign .title {
    background-color: $color-primary;
}

.body-sign .panel-sign .panel-body {
    border-top-color: $color-primary;
    padding: 33px 33px 10px;
}

.body-sign .panel-sign .panel-body .panel-footer {
    margin: 10px -33px -15px -33px;
}

.body-sign-md {
    max-width: 900px;
}

.body-sign-lg {
    max-width: $screen-lg;
}

.center-sign img {
    margin: 0;
}

.panel-sign {
    margin-top: -30px;
}

// Pricing Table on Subscription Page
// TODO: Convert to SASS once the compact pricing layout has been ported
.pricing-table .plan {
    margin-bottom: 0;
}

.pricing-table .most-popular h3 {
    background-color: $color-primary !important;
    color: $color-primary-inverse !important;
}

.pricing-table h3 {
    margin-bottom: 65px;
    padding-bottom: 5px;
}

.pricing-table h3 span {
    width: 125px;
    height: 125px;
    font: bold 25px / 115px $font-primary;
    color: $color-primary;
}

.prices-custom {
    font-weight: bold;
    color: $color-primary;
}

.d-modal-margin {
    margin: 0px auto; 
    max-width: 400px;
}

.d-modal-margin-btn {
    margin: 0px auto; 
    max-width: 350px;
}
