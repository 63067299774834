.pagination > li a {
    color: $color-primary;
}

.pagination > li a:hover,
.pagination > li a:focus {
    color: $color-primary-hover;
}

.pagination > li.active a,
.pagination > li.active span,
.pagination > li.active a:hover,
.pagination > li.active span:hover,
.pagination > li.active a:focus,
.pagination > li.active span:focus {
    background-color: $color-primary;
    border-color: $color-primary;
}

.pagination > li.active a {
    background-color: $color-primary;
}

div.row:last-child .pagination {
    margin-bottom: $spacement-increment;
}
