// Report Charts and Tables
.legend-container, .legend-low, .legend-medium, .legend-high, .legend-color-box, .legend-range {
    display: inline-block;
}

#legend-container {
    border: 1px solid $color-muted;
    padding: 5px;
    float: right;
}

#legend-container .legend {
    float: left;
    margin-right: 5px;
    height: 12px;
    line-height: 12px;
}

.legend .legend-item {
    float: left;
    margin-right: 4px;
    width: 16px;
    height: 12px;
}

.legend-container {
    float: right;
    width: 300px;
    padding: 5px;
}

.legend-color-box {
    height: 10px;
    width: 10px;
}

.legend-low,
.legend-medium,
.legend-high {
    width: 30%;
}

.legend-container,
.legend-color-box {
    border: 1px $color-default-inverse solid;
}

.legend-low .legend-color-box {
    background-color: $color-chart-usage-1;
}

.legend-medium .legend-color-box {
    background-color: $color-chart-usage-2;
}

.legend-high .legend-color-box {
    background-color: $color-chart-usage-3;
}

.highcharts-contextmenu hr {
    margin: 6px;
    background-color: none;
}

.percent-40 {
    color: $color-chart-usage-1;
}

.usage1, .empty {
    background-color: $color-chart-usage-1 !important;
}

.percent-41 {
    color: $color-chart-usage-2;
}

.usage2 {
    background-color: $color-chart-usage-2 !important;
}

.percent-71 {
    color: $color-chart-usage-3;
}

.usage3, .full, .box.inbound {
    background-color: $color-chart-usage-3 !important;
}

.usage4, .box.outbound {
    background-color: $color-chart-usage-4 !important;
}

.usage5, .box.disabled {
    background-color: $color-chart-usage-5 !important;
}

.locker-usage {
    color: $color-default-inverse !important
}

// Report Subscriptions
#btn-unsubscribe {
    margin-top: 27px;
}

@media only screen and (max-width: 767px) {
    #btn-unsubscribe {
        margin-top: 0;
    }
}

// Locker Availability
#locker-slider {
    margin: 15px auto 60px auto;
    width: 70%;
}

@media only screen and (max-width: 991px) {
    #locker-slider {
        margin: 15px 15px 60px auto;
        width: auto;
    }
}

@media only screen and (min-width: 992px) and (max-width: $screen-md-max) {
    #locker-slider {
        width: 80%;
    }
}

// Occupancy
#occupancyReport select#markDate {
    width: auto;
}

// Overview
#overviewReport .multiselect-container.dropdown-menu {
    left: 0;
    right: auto;
}

// Package Information
.table-package {
    tbody {
        td:nth-child(1) {
            width: 35%;
        }

        td:nth-child(2) {
            width: 65%;
        }
    }
}

// Property and Package Activity
.property-map {
    height: 500px;
}

.property-map-dashboard {
    height: 500px;
}

@media (min-width: $screen-lg) {
    .property-map-dashboard {
        height: 300px;
    }
}

.cluster {
    text-align: center;
    font-family: $font-primary;
    font-size: 0.8rem;
    font-weight: bold;
    color: $color-primary-inverse;
    cursor: pointer;
}

.cluster-1 {
    background-image: url('/img/marker-clusters/m1.png');
    line-height: 53px;
    width: 53px;
    height: 52px;
}

.cluster-2 {
    background-image: url('/img/marker-clusters/m2.png');
    line-height: 56px;
    width: 56px;
    height: 55px;
}

.cluster-3 {
    background-image: url('/img/marker-clusters/m3.png');
    line-height: 66px;
    width: 66px;
    height: 65px;
}
