// Alerts
.alert {
    ul {
        list-style-type: none;
        padding: 0;
    }
}

.alert-dismissable .close,
.alert-dismissible .close {
    top: 0;
}

.alert-primary {
    background-color: $color-primary-background;
    border-color: $color-primary;
    color: $color-primary;

    .alert-link {
        color: $color-black;
    }
}
