html body .tabs-primary .nav-tabs li a,
html body .tabs-primary .nav-tabs.nav-justified li a,
html body .tabs-primary .nav-tabs li a:hover,
html body .tabs-primary .nav-tabs.nav-justified li a:hover {
    color: $color-primary;
}

html body .tabs-primary .nav-tabs li a:hover,
html body .tabs-primary .nav-tabs.nav-justified li a:hover {
    border-top-color: $color-primary;
}

html body .tabs-primary .nav-tabs li.active a,
html body .tabs-primary .nav-tabs.nav-justified li.active a,
html body .tabs-primary .nav-tabs li.active a:hover,
html body .tabs-primary .nav-tabs.nav-justified li.active a:hover,
html body .tabs-primary .nav-tabs li.active a:focus,
html body .tabs-primary .nav-tabs.nav-justified li.active a:focus {
    border-top-color: $color-primary;
    color: $color-primary;
}

html body .tabs-primary.tabs-bottom .nav-tabs li a:hover,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:hover {
    border-bottom-color: $color-primary;
}

html body .tabs-primary.tabs-bottom .nav-tabs li.active a,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active a,
html body .tabs-primary.tabs-bottom .nav-tabs li.active a:hover,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active a:hover,
html body .tabs-primary.tabs-bottom .nav-tabs li.active a:focus,
html body .tabs-primary.tabs-bottom .nav-tabs.nav-justified li.active a:focus {
    border-top-color: #FFF;
    border-bottom-color: $color-primary;
}

html body .tabs-primary.tabs-vertical.tabs-left li a:hover {
    border-left-color: $color-primary;
}

html body .tabs-primary.tabs-vertical.tabs-left li.active a,
html body .tabs-primary.tabs-vertical.tabs-left li.active a:hover,
html body .tabs-primary.tabs-vertical.tabs-left li.active a:focus {
    border-left-color: $color-primary;
}

html body .tabs-primary.tabs-vertical.tabs-right li a:hover {
    border-right-color: $color-primary;
}

html body .tabs-primary.tabs-vertical.tabs-right li.active a,
html body .tabs-primary.tabs-vertical.tabs-right li.active a:hover,
html body .tabs-primary.tabs-vertical.tabs-right li.active a:focus {
    border-right-color: $color-primary;
}
