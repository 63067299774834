// Text and Background Colors
.bg-primary {
    background-color: $color-primary !important;
}

.bg-secondary {
    background-color: $color-secondary !important;
}

.bg-warning {
    background-color: $color-warning !important;
}

.bg-danger {
    background-color: $color-danger !important;
}

.highlight {
    background-color: $color-primary;
}

.label-primary {
    background: $color-primary;
}

.label-secondary {
    background: $color-secondary;
}

.text-primary {
    color: $color-primary !important;
}

.text-secondary {
    color: $color-secondary !important;
}

// HTML Elements
body {
    font-family: $font-primary;
}

blockquote {
    &.primary {
        border-color: $color-primary;
    }

    &.secondary {
        border-color: $color-secondary;
    }

    &.tertiary {
        border-color: $color-tertiary;
    }

    &.quaternary {
        border-color: $color-quaternary;
    }
}

p.drop-caps.colored:first-child:first-letter {
    color: $color-primary;
}

p.drop-caps.colored.secondary:first-child:first-letter {
    background-color: $color-primary;
}

// Layout Fixes
.no-padding {
    padding: 0;
}

.no-margin {
    margin: 0;
}

// Layout Boxed - Narrower than $screen-lg
@media only screen and (max-width: $screen-md-max) {
    html.boxed .header {
        border-top-color: $color-primary;
    }
}

// Layout Boxed - Wider or equal to $screen-lg
@media only screen and (min-width: $screen-lg) {
    html.boxed .header {
        border-top-color: $color-primary;
    }

    html.boxed .sidebar-right {
        border-top-color: $color-primary;
        min-height: 0;
    }
}

// Panels
.panel-body.bg-primary {
    background: $color-primary;
}

.panel-featured-primary {
    border-color: $color-primary;
}

.panel-featured-primary .panel-title {
    color: $color-primary;
}

.panel-heading p {
    color: $color-muted;
}

.panel-heading.bg-primary {
    background: $color-primary;
}

.panel-heading-icon.bg-primary {
    background: $color-primary;
}

.panel-highlight .panel-heading {
    background-color: $color-primary;
    border-color: $color-primary;
}

.panel-highlight .panel-body {
    background-color: $color-primary;
}

.panel-padding {
    padding: 0 40px;

    &:first-child {
        padding-top: 40px;
    }
}

html .panel-primary .panel-heading {
    background: $color-primary;
    border-color: $color-primary;
}

.panel-subtitle {
    line-height: 22px;
}

// Panels (Accordions)
.panel-group .panel-accordion .panel-heading a {
    color: $color-primary;
}

.panel-group .panel-accordion-primary .panel-heading .panel-title a {
    background: $color-primary;
}

// Sidebar
.sidebar-left .sidebar-header .sidebar-toggle:hover i {
    color: $color-primary;
}

@media only screen and (min-width: 768px) {
    html.sidebar-left-collapsed.scroll .sidebar-left .nav-main li.nav-active a:hover,
    html.sidebar-left-collapsed.boxed .sidebar-left .nav-main li.nav-active a:hover {
        color: $color-primary;
    }

    html.sidebar-left-collapsed.scroll .sidebar-left .nav-main > li:hover > a span.label,
    html.sidebar-left-collapsed.boxed .sidebar-left .nav-main > li:hover > a span.label {
        background-color: $color-primary;
    }
}

@media only screen and (max-width: $screen-md-max) {
    .sidebar-left {
        width: $sidebar-left-xs-full-width;
    }
}

.sidebar-left .sidebar-header .sidebar-toggle {
    left: 0;
    border-radius: 0;

    i {
        position: relative;
        left: -2px;
    }
}

// Wells
.well.primary {
    background: $color-primary;
    border-color: $color-primary-disabled;
}


// Wizards
.wizard-steps > li.active .badge {
    background-color: $color-primary;
}

.wizard-steps > li.active a,
.wizard-steps > li.active a:hover,
.wizard-steps > li.active a:focus {
    border-top-color: $color-primary;
}

.wizard-tabs ul > li.active .badge {
    background-color: $color-primary;
}

html .wizard-progress .steps-progress .progress-indicator {
    background: $color-primary;
}

html .wizard-progress .wizard-steps li.completed a span {
    border-color: $color-primary;
    background: $color-primary;
}

html .wizard-progress .wizard-steps li.active a span {
    color: $color-primary;
    border-color: $color-primary;
}
